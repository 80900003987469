import { lightTheme, type GlobalThemeOverrides, type MessageProviderProps } from 'naive-ui';
import variables from '../assets/scss/config/variables.module.scss';

type ThemeColors = {
  hoverColor: string;
  primaryColor: string;
};

export const messagePlacement: MessageProviderProps['placement'] = 'top-right';

export const combinePropWithTypesAndActions = (
  propName: string,
  propValue: string,
  types: string[],
  actions: string[],
): Record<string, string> => {
  return Object.fromEntries(
    types.flatMap((type) => actions.map((action) => `${propName}${action}${type}`)).map((prop) => [prop, propValue]),
  );
};

const defaultThemeColors: ThemeColors = {
  hoverColor: variables.lightBlue2,
  primaryColor: variables.primaryBlue,
};

export const getGlobalThemeOverrides = (themeColors: ThemeColors = defaultThemeColors): GlobalThemeOverrides => {
  return {
    ...getGeneralThemeOverrides(themeColors),
    ...getInputThemeOverrides(themeColors.primaryColor),
    ...getFormThemeOverrides(),
    ...getMenuThemeOverrides(),
    ...getMessageThemeOverrides(),
  };
};

export const getGeneralThemeOverrides = ({ hoverColor, primaryColor }: ThemeColors): GlobalThemeOverrides => {
  const textColor = variables.white;
  const border = `1px solid ${hoverColor}`;
  const actions = ['Hover', 'Pressed', 'Focus'];
  const buttonTypes = ['', 'Primary'];
  const spinColor = variables.white;
  const dividerColor = variables.darkBlue;
  const switchColor = variables.lightBlue2;

  return {
    common: {
      primaryColor,
      borderRadius: '5px',
    },
    Divider: {
      color: dividerColor,
    },
    Spin: {
      color: spinColor,
    },
    Switch: {
      railColorActive: switchColor,
    },
    Button: {
      textColor,
      textColorPrimary: textColor,
      ...combinePropWithTypesAndActions('textColor', textColor, buttonTypes, actions),
      ...combinePropWithTypesAndActions('color', hoverColor, buttonTypes, actions),
      border,
      ...combinePropWithTypesAndActions('border', border, buttonTypes, actions),
      colorSecondary: 'transparent',
      colorSecondaryHover: variables.mediumGreen,
      colorQuaternaryHover: 'transparent',
    },
    Tooltip: {
      color: variables.tooltipColor,
    },
    Progress: {
      fillColorSuccess: variables.successColor,
    },
    Layout: {
      headerColor: variables.sidebarBackgroundColor,
      siderColor: variables.sidebarBackgroundColor,
      textColor: variables.labelTextColor,
    },
  };
};

export const getMessageThemeOverrides = (): GlobalThemeOverrides => {
  const maxWidth = '500px';
  const closeIconSize = '20px';
  const lineHeight = '2.5';

  return {
    Message: {
      ...lightTheme.Message,
      colorError: variables.errorColor,
      textColorError: variables.white,
      closeIconColorError: variables.lightskyBlue,
      colorSuccess: variables.successColor,
      textColorSuccess: variables.white,
      closeIconColorSuccess: variables.lightskyBlue,
      colorInfo: variables.lightBlue2,
      textColorInfo: variables.white,
      closeIconColorInfo: variables.lightskyBlue,
      lineHeight: lineHeight,
      maxWidth: maxWidth,
      closeIconSize: closeIconSize,
    },
  };
};

export const getConfirmModalThemeOverrides = (): GlobalThemeOverrides => {
  return {
    Dialog: {
      closeSize: '30px',
      closeIconColor: '#000',
      closeIconColorHover: '#000',
      closeBorderRadius: '.25rem',
      closeMargin: '.5rem .5rem 0 0',
      padding: 0,
      color: variables.grayishBlue,
    },
  };
};

export const getCardThemeOverrides = (): GlobalThemeOverrides => {
  const cardBackgroundColor = variables.grayishBlue;
  const descriptionColor = variables.labelTextColor;
  const textColor = variables.white;
  const fontSize = '14px';
  const titleFontSize = '20px';
  const mediumAndLessPadding = '26px 20px';
  const largeAndMorePadding = '30px 40px';
  const borderRadius = '10px';
  const descriptionMargin = '0 0 10px 0';

  return {
    Card: {
      paddingSmall: mediumAndLessPadding,
      paddingMedium: mediumAndLessPadding,
      paddingLarge: largeAndMorePadding,
      paddingHuge: largeAndMorePadding,
      color: cardBackgroundColor,
      titleFontSizeSmall: titleFontSize,
      titleFontSizeMedium: titleFontSize,
      titleFontSizeLarge: titleFontSize,
      titleFontSizeHuge: titleFontSize,
      fontSizeSmall: fontSize,
      fontSizeMedium: fontSize,
      fontSizeLarge: fontSize,
      fontSizeHuge: fontSize,
      borderColor: cardBackgroundColor,
      borderRadius: borderRadius,
      textColor: textColor,
      titleTextColot: textColor,
    },
    Typography: {
      pTextColor: descriptionColor,
      pMargin: descriptionMargin,
    },
  };
};

export const getFormThemeOverrides = (): GlobalThemeOverrides => {
  return {
    Form: {
      asteriskColor: variables.errorColor,
      feedbackTextColorError: variables.errorColor,
      feedbackFontSizeLarge: '0.875em',
      labelFontSizeTopLarge: '12px',
      labelTextColor: variables.labelTextColor,
    },
  };
};

export const getInputThemeOverrides = (primaryColor: string): GlobalThemeOverrides => {
  const textColor = variables.white;
  const borderType = '1px solid';
  const initBorderColorMap = {
    border: variables.lightBlue,
    borderError: variables.errorColor,
    borderSuccess: variables.successColor,
    borderInfo: variables.lightBlue2,
    borderHover: primaryColor,
    borderDisabled: variables.lightBlue,
    borderFocus: variables.lightBlue,
  };
  const appendedBorderColorMap = appendObjectFields(initBorderColorMap, borderType);

  return {
    Input: {
      textColor,
      color: variables.lightBlue,
      ...appendedBorderColorMap,
      heightLarge: '45px',
      paddingLarge: '0 20px',
      caretColor: textColor,
    },
    InternalSelection: {
      textColor,
      color: variables.lightBlue,
      ...appendedBorderColorMap,
      heightLarge: '45px',
      paddingLarge: '0 20px',
      caretColor: textColor,
    },
    InternalSelectMenu: {
      textColor,
      color: variables.lightBlue,
      ...appendedBorderColorMap,
      heightLarge: '45px',
      paddingLarge: '0 20px',
      caretColor: textColor,
    },
    DatePicker: {
      panelColor: variables.lightBlue,
      itemColorActive: textColor,
      itemTextColor: textColor,
    },
  };
};

export const getDataTableThemeOverrides = (): GlobalThemeOverrides => {
  return {
    DataTable: {
      borderColor: variables.lightGray,
      borderRadius: '0',
      thColor: variables.grayishBlue,
      thColorHover: variables.grayishBlue,
      tdColorHover: variables.grayishBlue,
      tdColor: variables.lightBlue,
      paginationMargin: '12px',
    },
    List: {
      color: 'transparent',
    },
  };
};

export const getGreenButtonThemeOverrides = (): GlobalThemeOverrides => {
  const global = getGlobalThemeOverrides({
    primaryColor: variables.mediumGreen,
    hoverColor: variables.mediumGreen,
  });

  return {
    ...global,
    Button: {
      ...global.Button,
      textColor: variables.mediumGreen,
    },
  };
};

export const getDotsDropdownMenuThemeOverrides = (): GlobalThemeOverrides => {
  return {
    Dropdown: {
      color: variables.darkBlue,
      optionColorHover: variables.darkBlue,
      optionTextColor: variables.white,
      optionTextColorHover: variables.white,
      padding: '14px',
    },
    Icon: {
      color: variables.white,
    },
  };
};

export const getMenuThemeOverrides = (): GlobalThemeOverrides => {
  return {
    Menu: {
      itemIconColor: variables.menuInactive,
      itemTextColor: variables.menuInactive,
      itemIconColorActive: variables.white,
      itemTextColorActive: variables.white,
      itemTextColorActiveHover: variables.white,
      itemIconColorActiveHover: variables.white,
      itemColorHover: variables.mediumGreen,
      itemColorActive: variables.mediumGreen,
      itemColorActiveHover: variables.mediumGreen,
      itemColorActiveCollapsed: variables.mediumGreen,
    },
  };
};
